import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Modal,
  notification,
  Space,
  Switch,
  Button,
  Form,
  Input,
  Radio,
} from "antd";
import CompanyService from "../services/company.service";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { confirm } = Modal;

function TableCompany({ id }) {
  const columnsCompany = [
    {
      title: "Id",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "LineName",
      dataIndex: "LineName",
      key: "LineName",
    },
    {
      title: "LineId",
      key: "LineId",
      dataIndex: "LineId",
    },
    {
      title: "CustomerId",
      key: "CustomerId",
      dataIndex: "CustomerId",
    },
    {
      title: "CustomerType",
      key: "CustomerType",
      dataIndex: "CustomerType",
    },
    {
      title: "Language",
      key: "Language",
      dataIndex: "Language",
    },
    {
      title: "ModifiedDate",
      key: "ModifiedDate",
      dataIndex: "ModifiedDate",
    },
    {
      title: "Action",
      key: "action",
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Deactive",
          value: null,
        },
      ],
      onFilter: (value, record) => record.Status === value,
      filterSearch: true,
      render: (text, record) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Deactive"
          checked={text.Status === "Active" ? true : false}
          onClick={() => showConfirm(text.Id, text.Status)}
          //onChange={showConfirm(text.Id)}
        />
      ),
    },
  ];
  // state column
  const [columns, setColumns] = useState(columnsCompany);

  // state table
  const [company, setCompany] = useState([]);
  // const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalMultiStatus, setModalMultiStatus] = useState(false);
  const [modalMultiUnlinkMenu, setModalMultiUnlinkMenu] = useState(false);
  const [modalMultiLinkMenu, setModalMultiLinkMenu] = useState(false);
  const [statusValue, setStatusValue] = useState("Active");

  // fetch data
  const fetch = async (params = { page: 1, pageSize: 10 }) => {
    try {
      setLoading(true);
      const ret = await CompanyService.getLineOaByCompanyId(id);
      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = ret.data.recordset;
      console.log("data: ", data);
      setLoading(false);
      setCompany(data);

      setColumns(columnsCompany);
    } catch (error) {
      return;
    }
  };

  const showConfirm = (id, status) => {
    let dataValue = {
      Id: id,
      Status: status === "Active" ? "Deactive" : "Active",
    };
    confirm({
      title: "Comfirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to change status?",
      onOk() {
        CompanyService.updateStatusLineOaCompany(dataValue)
          .then((data) => {
            setLoading(true);
            openNotificationWithIcon("success");
            fetch();
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log("Oops errors!"));
          })
          .catch((err) => {
            openNotificationWithIcon("error");
            console.log("Oops errors!");
          });
      },
      onCancel() {},
    });
  };

  const onSelectChange = (selectedRowKeys) => {
    //console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeStatus = (e) => {
    console.log("radio checked", e.target.value);
    setStatusValue(e.target.value);
  };

  const CollectionStatusForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={visible}
        title="Change Status"
        okText="Save"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          onCreate();
        }}
      >
        <Radio.Group onChange={onChangeStatus} value={statusValue}>
          <Radio value={"Active"}>Active</Radio>
          <Radio value={"Deactive"}>Deactive</Radio>
        </Radio.Group>
      </Modal>
    );
  };

  const onSubmitStatus = () => {
    let dataValue = {
      Id: selectedRowKeys,
      Status: statusValue,
      CompanyId: id,
    };
    CompanyService.multiUpdateStatusLineOaCompany(dataValue)
      .then((data) => {
        setLoading(true);
        openNotificationWithIcon("success");
        setModalMultiStatus(false);
        setSelectedRowKeys([]);
        fetch();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        console.log("Oops errors!");
      });
  };

  const showConfirmMultiUnlinkMenu = () => {
    confirm({
      title: "Comfirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to change status?",
      onOk() {
        let dataValue = {
          Id: selectedRowKeys,
          CompanyId: id,
        };
        CompanyService.lineOaCompanyUnlinkMultiMenu(dataValue)
          .then((data) => {
            setLoading(true);
            openNotificationWithIcon("success");
            fetch();
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log("Oops errors!"));
          })
          .catch((err) => {
            openNotificationWithIcon("error");
            console.log("Oops errors!");
          });
      },
      onCancel() {},
    });
  };

  const CollectionRichMenuForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={visible}
        title="Create a new company"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            Select: "",
          }}
        >
          <Form.Item
            name="richmenuen"
            label="Rich Menu EN"
            rules={[
              {
                required: true,
                message: "Please input the Rich Menu EN!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="richmenuth"
            label="Rich Menu TH"
            rules={[
              {
                required: true,
                message: "Please input the Rich Menu TH!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const onCreate = (values) => {
    let dataValue = {
      Id: selectedRowKeys,
      CompanyId: id,
      RichMenuIdTH: values.richmenuth,
      RichMenuIdEN: values.richmenuen,
    };
    CompanyService.lineOaCompanyLinkMultiMenu(dataValue)
      .then((data) => {
        setLoading(true);
        openNotificationWithIcon("success");
        setModalMultiLinkMenu(false);
        fetch();
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log("Oops errors!"));
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        console.log("Oops errors!");
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Space style={{ float: "right", margin: "10px" }}>
        <Button
          key="1"
          type="primary"
          onClick={() => setModalMultiStatus(true)}
        >
          Change Status
        </Button>
        <Button
          key="2"
          type="primary"
          onClick={() => showConfirmMultiUnlinkMenu()}
        >
          Unlink Rich Menu
        </Button>
        <Button
          key="3"
          type="primary"
          onClick={() => setModalMultiLinkMenu(true)}
        >
          Link Rich Menu
        </Button>
      </Space>
      <CollectionStatusForm
        key="multistatus"
        visible={modalMultiStatus}
        onCreate={onSubmitStatus}
        onCancel={() => {
          setModalMultiStatus(false);
        }}
      />
      <CollectionRichMenuForm
        key="mult"
        visible={modalMultiLinkMenu}
        onCreate={onCreate}
        onCancel={() => {
          setModalMultiLinkMenu(false);
        }}
      />
      <Table
        rowSelection={rowSelection}
        columns={columns}
        rowKey={(record) => record.Id}
        dataSource={company}
        // pagination={pagination}
        loading={loading}
        // onChange={handleTableChange}
        scroll={false}
        className="table-row-pointer"
      />
    </>
  );
}
// ••••••••••••••••••••

const openNotificationWithIcon = (type) => {
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: "Successfully.",
    });
  } else {
    notification[type]({
      message: "Error",
      description: "Something when wrong.",
    });
  }
};

export default TableCompany;
